import {lazy} from "react";
import {ITenant} from "./common/types/ITenant";

const TENANT = process.env.REACT_APP_TENANT as ITenant | undefined;

const CratosApp = lazy(() => import("./cratos"));
const AdaApp = lazy(() => import("./ada"));

export const App = () => {
  return <div>{TENANT === "ADA" ? <AdaApp /> : <CratosApp />}</div>;
};
