import {createRoot} from "react-dom/client";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {asyncWithLDProvider} from "launchdarkly-react-client-sdk";
import {hasWindow} from "./cratos/utils/app";
import "./index.css";
import {App} from "./App";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "0946e5b5-84a6-43cc-a153-1a60b0e2c774",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: hasWindow()
      ? window?._env_?.REACT_APP_LD_CLIENT_ID
      : process.env.REACT_APP_LD_CLIENT_ID,
    options: {},
  });

  const root = createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  );
})();
